import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  arMenues: any = [];
  modalType: string = '';
  title = null;
  subTitle = '';
  isShow = false
  modalFor = '';
  CIFObj: any;
  arBookingDetails = '';
  isReverseBtn = false;
  cancelBtnLabel = 'Cancel';
  submitBtnLabel = 'Yes, I am sure';
  // showAboutUsLink = true;

  activeheaderaboutUs = true;
  activeheaderourSolutions = true;
  activeheaderindustries = true;
  activeheadersustainibility = true;

  aboutUs = true;
  ourSolutions = true;
  industries = true;
  sustainibility = true;
  dataTargetValue = '#collapseOne';   // for dynamic back btn
  showHideLink1 = true; // for expand collapse link
  showHideLink2 = true;
  showHideLink3 = true;
  isSticky: boolean = false;
  isShowProfileBox: boolean = false;
  notificationData: any = [];
  seenCount = 0;
  refreshSeenApi = false;
  isNotificationPopUp: boolean = false;
  showMaintenance = false;
  contentHeight = '34px';
  currentRoute = '';
  arrRoutes = []
  @ViewChild('maintenance') maintenance: ElementRef | undefined;
  currentUrl: string = '';

  constructor(
    private router: Router,
    public _router: Router,

  ) {
  }


  isShowMobileNavigationBox: boolean = false;
  currentAppLang: any;
  arLanguage: any = [
    {
      szName: "EN",
      szValue: "en"
    },
    {
      szName: "简体中文",
      szValue: "ch"
    }
  ];

  userAccessLocation: any;
  arLocationsList: any = [];

  toggleProfileBox() {
    this.isShowProfileBox = !this.isShowProfileBox;
  }

  ngOnInit() {
    // await this._bootstrapData.load();
    this.userAccessLocation = 'India';
    this.setUserAccessLocationCookie(this.userAccessLocation);
    this.getBlockDomainList();
    this.activateLinkMenus();
    this.subscribeDataChanges();
    this.currentUrl = window.location.href;
  }

  subscribeDataChanges() {
    // this._auth.authDataCast.subscribe((_data: any) => {
    //   if (this._auth.isUserLoggedIn) {
    //     this.getNotification();
    //   }
    // });
  }

  switchLang(eValue: any) {
    // if (eValue) {
    //   this._lang.setAppLanguage(eValue.szValue);
    //   this.translate.use(eValue.szValue).toPromise();
    // } else {
    //   let lang = this._lang.getAppLanguage();
    //   this._lang.setAppLanguage(lang);
    //   this.translate.use(lang).toPromise();
    // }
  }

  switchLocation(eValue: any) {
    // if (eValue) {
    //   this._lang.setAppLocation(eValue.szCountryName);
    // }
  }

  handleLoginSignupButton(modalName: any, isLogin: boolean = true) {
    // this._modal.toggleModal(true);
    // this._modal.updateLoginTab(isLogin);
    // this._modal.updateModalType(modalName);
    let req = {};
  }

  addActivitylogs() {

  }

  deleteFrontendAuthData() {
    // this._xDomainService.deleteXdomainUserCookie();
    // this._xDomainService.deleteXdomainUserNotificationsCookie();
    // this._auth.removeAuthUserData();
    this.router.navigate(['/']);
  }

  activateLinkMenus() {
    // $('.megamenu-bottom').hover(function () {
    //   $(this).find('.ham-dropdown').first().stop(true, true).fadeIn("fast");
    // }, function () {
    //   $(this).find('.ham-dropdown').first().stop(true, true).fadeOut("fast");
    // });
  }

  navigateToExternalLink(url: string, sameTab: boolean = true) {

  }

  globalSearch(value: any, sameTab: boolean = true) {

  }

  sendOutboundAnalytics(szUrl: string) {
    let arPageTrackingData = {
      "szCategory": "Outbound",
      "szAction": "button_click",
      "szLabel": szUrl,
      "szEventValue": ""
    };
    // this._PageTrackingService.sendPageTracking(arPageTrackingData);
  }

  logoutConfirmation() {
    this.isShowProfileBox = false;
    this.modalType = "danger";
    // this.title = this.translate.instant("Are you Sure you want \n to Logout now?");
    // this.title = this.translate.instant("Are you Sure you want") + "\ (n " + this.translate.instant("to Logout now?") + ")";
    // this.title = this.translate.instant("Are you Sure you want to Logout now?");
    this.subTitle = "";
    this.isShow = true;
    this.modalFor = "logOutUser";
    this.isReverseBtn = true;
  }

  popupCallBack(response: { responseType: string; }) {
    if (response && response.responseType == 'Confirm' && this.modalFor == "logOutUser") {
      this.router.navigate(['/logout']);
    }
    this.modalFor = "";
    this.isShow = false;
  }
  navToggel() {
    this.isShowMobileNavigationBox = !this.isShowMobileNavigationBox;
  }
  clickedAboutUsLink(useClick = false, name?: string) {  // to add class(d-none) on click About Us link
    if (name) {
      switch (name) {
        case 'swireShipping':
          // this.aboutUs = true;
          // this.ourSolutions = !(this.ourSolutions);
          // this.industries = !(this.industries);
          // this.sustainibility = !(this.sustainibility);
          document.getElementsByClassName('btn-link')[0].setAttribute("aria-expanded", "false");
          // this._router.navigateByUrl('');
          break;
        case 'ourSolutions':
          // this.aboutUs = !(this.aboutUs);
          // this.ourSolutions = true;
          // this.industries = !(this.industries);
          // this.sustainibility = !(this.sustainibility);
          document.getElementsByClassName('btn-link')[1].setAttribute("aria-expanded", "false");
          break;
        case 'ourStory':
          // this.aboutUs = !(this.aboutUs);
          // this.ourSolutions = !(this.ourSolutions);
          // this.industries = true;
          // this.sustainibility = !(this.sustainibility);
          document.getElementsByClassName('btn-link')[2].setAttribute("aria-expanded", "false");
          this._router.navigateByUrl('ourstory');
          break;
        case 'contact':
          // this.aboutUs = !(this.aboutUs);
          // this.ourSolutions = !(this.ourSolutions);
          // this.industries = !(this.industries);
          // this.sustainibility = true;
          document.getElementsByClassName('btn-link')[3].setAttribute("aria-expanded", "false");
          break;
        default:
          break;
      }
    }
    if (useClick) {
      const expandVal1 = document.getElementById('activeheaderaboutUs')?.getAttribute('aria-expanded');
      const expandVal2 = document.getElementById('activeheaderourSolutions')?.getAttribute('aria-expanded');
      const expandVal3 = document.getElementById('activeheaderindustries')?.getAttribute('aria-expanded');
      const expandVal4 = document.getElementById('activeheadersustainibility')?.getAttribute('aria-expanded');

      if (expandVal1 && expandVal1 == 'true') {
        this.activeheaderaboutUs = false;
        this.dataTargetValue = '#collapseOne';
        document.getElementsByClassName('btn-link')[0].setAttribute("aria-expanded", "false");
      } else {
        this.activeheaderaboutUs = true;
      }
      if (expandVal2 && expandVal2 == 'true') {
        this.activeheaderourSolutions = false;
        this.dataTargetValue = '#collapseTwo';
        document.getElementsByClassName('btn-link')[1].setAttribute("aria-expanded", "false");
      } else {
        this.activeheaderourSolutions = true;
      }
      if (expandVal3 && expandVal3 == 'true') {
        this.activeheaderindustries = false;
        this.dataTargetValue = '#collapseThree';
        document.getElementsByClassName('btn-link')[2].setAttribute("aria-expanded", "false");
      } else {
        this.activeheaderindustries = true;
      }
      if (expandVal4 && expandVal4 == 'true') {
        this.activeheadersustainibility = false;
        this.dataTargetValue = '#collapseFour';
        document.getElementsByClassName('btn-link')[3].setAttribute("aria-expanded", "false");
      }
      else {
        this.activeheadersustainibility = true;
      }
    }
    if (!name && !useClick) {
      this.aboutUs = true;
      this.ourSolutions = true;
      this.industries = true;
      this.sustainibility = true;
    }
  }

  getBlockDomainList() {
    let that = this;
    // let blockDomainData = this._storageService.getDataFromStorage('blockDomainData');
    // if (blockDomainData != '' && blockDomainData != null && blockDomainData != undefined) {

    // } else {
    //   this._auth.getBlockDomainList().subscribe(
    //     (response) => {
    //       if (response['responseData'] != null && response['responseData'] != '' && response['responseData'] != undefined) {

    //         that._storageService.putDataInStorage('blockDomainData', JSON.stringify(response['responseData']));
    //       }
    //     },
    //     (error) => {


    //     }
    //   );
    // }
  }

  goBack() { // back btn in mob navigation
    const expandVal1 = document.getElementById('activeheaderaboutUs')?.getAttribute('aria-expanded');
    const expandVal2 = document.getElementById('activeheaderourSolutions')?.getAttribute('aria-expanded');
    const expandVal3 = document.getElementById('activeheaderindustries')?.getAttribute('aria-expanded');
    const expandVal4 = document.getElementById('activeheadersustainibility')?.getAttribute('aria-expanded');

    if (expandVal1 && expandVal1 == 'true') {
      $('#collapseOne').removeClass('show');
      $('#activeheaderaboutUs').addClass('collapsed');
      this.clickedAboutUsLink(true, 'abouUS');
    } else if (expandVal2 && expandVal2 == 'true') {
      $('#collapseTwo').removeClass('show');
      $('#activeheaderourSolutions').addClass('collapsed');
      this.clickedAboutUsLink(true, 'ourSolutions');
    } else if (expandVal3 && expandVal3 == 'true') {
      $('#collapseThree').removeClass('show');
      $('#activeheaderindustries').addClass('collapsed');
      this.clickedAboutUsLink(true, 'industries');
    } else if (expandVal4 && expandVal4 == 'true') {
      $('#collapseFour').removeClass('show');
      $('#activeheadersustainibility').addClass('collapsed');
      this.clickedAboutUsLink(true, 'sustainibility');
    }
  }

  expandCollapseLink(name: string) {  // for expand collapse link
    if (name === 'showHideLink1') {
      this.showHideLink1 = !(this.showHideLink1);
    } else if (name === 'showHideLink2') {
      this.showHideLink2 = !(this.showHideLink2);
    } else if (name === 'showHideLink3') {
      this.showHideLink3 = !(this.showHideLink3);
    }

  }
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 80;
  }

  addActivityLog(postData: any) {
  }
  setUserAccessLocationCookie(useraccessLocation: any) {

  }

  setUserNotificationsCookie(userNotifications: any) {
    let objData = { "user_notifications": userNotifications };
    let cookie = this.readCookie('x-domain-user-notifications');
    // if (!cookie) {
    //   this._xDomainService.setXdomainUserNotificationsCookie(objData);
    // }
  }

  readCookie(name: string) {
    // var nameEQ = name + "=";
    // var ca = document.cookie.split(';');
    // for (var i = 0; i < ca.length; i++) {
    //   var c = ca[i];
    //   while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    //   if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    // }
    // return null;
  }
  private getNotification() {
    const payload = {
      szNotificationPage: 'header'
    };
    // this._userService.getCustomerNotification(payload).subscribe(data => {
    //   this.notificationData = data['data']['arNotification'];
    //   this.seenCount = data['data']['unSeenCount'];
    //   if (this._auth.isUserLoggedIn) {
    //     this.setUserNotificationsCookie(data['data']);
    //   }
    //   this.notificationData.map(elm => {
    //     if (elm?.bSeen !== 1) {
    //       this.refreshSeenApi = true;
    //     }
    //   });
    // });
  }
  toggleNotificationModal(isShow: boolean = false) {
    if (isShow) {
      this.isNotificationPopUp = true;
    } else {
      this.isNotificationPopUp = false;
    }
  }
  getContainerNo(item: { messageData: string; }) {
    let containerNo = this.arrayFindObjectByProp(JSON.parse(item.messageData), "data_key", "cm_container_number");
    return containerNo;
  }

  arrayFindObjectByProp(arr: any, prop: any, val: any) {
    let data = arr?.find((obj: any) => obj[prop] == val);
    if (data?.data_value) {
      return data.data_value;
    } else {
      return null;
    }
  }
  showPanel(type: any) {
    // this._layout.setPannelType(type);
    // this._layout.pannelType = type;
    // localStorage.setItem('pannelType', JSON.stringify(type));
    this.isShowProfileBox = !(this.isShowProfileBox);
    if (type == 1) {
      this.addActivitylogs();
    }
  }
  // error alert popup
  ngAfterViewInit() {
    if (this.showMaintenance) {
      this.contentHeight = this.maintenance?.nativeElement?.offsetHeight + 'px';
    }
  }
  hideMaintenanceMessage() {
    this.showMaintenance = false;
    // this._storageService.putDataInStorage('maintenancePopup', JSON.stringify(1));
  }
  gotoFooter() {
    // @ts-ignore: Object is possibly 'null'.
    document.getElementById('targetFooter').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  }
}
