<nav
  class="swire-v3-container outer-header"
  [ngClass]="{
    'sticky-header bg-Primary-100': isSticky,
    'inner-header': _router.url == '/ourstory'
  }"
>
  <div class="nav-full-width">
    <div class="header-home">
      <h1 class="logo">
        <a href="javascript:void(0)" routerLink="/">
          <img src="../../../../assets/media/cnco-logo.png" />
        </a>
      </h1>
    </div>

    <ul class="bottom-bar-header">
      <li class="megamenu-bottom swire-logo">
        <a
        target="_blank"
          href="https://www.swireshipping.com/"
          class="red-border Display-family paragraph-bold-20 Text-10"
        >
          <img src="../../../../assets/media/swire-logo.png" />
        </a>
      </li>
      <li class="megamenu-bottom swire-bulk">
        <a
        target="_blank"
          href="https://swirebulk.com/"
          class="Display-family red-border paragraph-bold-20 Text-10"
        >
          <img src="../../../../assets/media/swire-bulk.png" />
        </a>
      </li>
      <li class="megamenu-bottom">
        <a
          routerLink="/ourstory"
          href="javascript:void(0);"
          class="red-border Display-family paragraph-bold-20 Text-10"
          >Our Story</a
        >
      </li>

      <li class="megamenu-bottom">
        <a
          (click)="gotoFooter()"
          class="red-border Display-family paragraph-bold-20 Text-10"
          >Contact</a
        >
      </li>
    </ul>
  </div>
</nav>

<div
  class="nav-header-m outer-header"
  [ngClass]="{
    'sticky-header bg-Primary-100': isSticky,
    'inner-header': _router.url == '/ourstory'
  }"
>
  <a href="/" class="logo">
    <img src="../../../../assets/media/cnco-logo.png" />
  </a>
  <a
    href="javascript:void(0);"
    class="bar-icon"
    (click)="navToggel(); clickedAboutUsLink()"
  >
    <span class="swi-icon icon-hamburger Text-10 icon-size-32"></span>
  </a>
</div>

<!-- for-mobile navigation -->
<div class="nav-modal" *ngIf="isShowMobileNavigationBox">
  <div class="search-close">
    <div
      class="back-btn"
      aria-expanded="false"
      [ngClass]="
        aboutUs && ourSolutions && industries && sustainibility ? 'd-none' : ''
      "
    >
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="back-icon"
          ><svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.875 1.71429L2.65625 6L6.875 10.2857L6.03125 12L0.125 6L6.03125 -3.68815e-08L6.875 1.71429Z"
              fill="#FCFCFD"
            />
          </svg>
        </span>
        <span class="back-text paragraph-semibold-15">'Back'</span>
      </a>
    </div>
    <a href="javascript:void(0);" (click)="navToggel(); goBack()">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.32922 1.32934C1.54618 1.11239 1.89794 1.11239 2.1149 1.32934L6.99984 6.21428L11.8848 1.32934C12.1017 1.11239 12.4535 1.11239 12.6705 1.32934C12.8874 1.5463 12.8874 1.89806 12.6705 2.11502L7.78551 6.99996L12.6705 11.8849C12.8874 12.1019 12.8874 12.4536 12.6705 12.6706C12.4535 12.8875 12.1017 12.8875 11.8848 12.6706L6.99984 7.78563L2.1149 12.6706C1.89794 12.8875 1.54618 12.8875 1.32922 12.6706C1.11226 12.4536 1.11226 12.1019 1.32922 11.8849L6.21416 6.99996L1.32922 2.11502C1.11226 1.89806 1.11226 1.5463 1.32922 1.32934Z"
          fill="white"
        />
      </svg>
    </a>
  </div>
  <div
    class="translate-country paragraph-semibold-15"
    [ngClass]="
      aboutUs && ourSolutions && industries && sustainibility ? '' : 'd-none'
    "
  >
    <span class="languageSwitch"> </span>
  </div>
  <div id="accordion">
    <div class="card" [ngClass]="aboutUs ? '' : 'd-none'">
      <div
        class="card-header"
        id="headingOne"
        [ngClass]="aboutUs ? 'active-header' : 'active-header-ss'"
      >
        <h5 class="mb-0 paragraph-semibold-18">
          <button
            id="activeheaderaboutUs"
            class="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            (click)="clickedAboutUsLink(true, 'swireShipping')"
          >
            <span class="swire-logo">
              <a target="_blank" href="https://www.swireshipping.com/">
                <img src="../../../../assets/media/swire-logo.png" />
              </a>
            </span>
            <!-- About Us -->
          </button>
        </h5>
      </div>
    </div>

    <div class="card" [ngClass]="ourSolutions ? '' : 'd-none'">
      <div
        class="card-header"
        id="headingTwo"
        [ngClass]="ourSolutions ? 'active-header' : ''"
      >
        <h5 class="mb-0 paragraph-semibold-18">
          <button
            id="activeheaderourSolutions"
            class="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
            (click)="clickedAboutUsLink(true, 'ourSolutions')"
          >
            <span class="swire-bulk">
              <a target="_blank" href="https://swirebulk.com/">
                <img src="../../../../assets/media/swire-bulk.png" />
              </a>
            </span>
            <!-- Find A Sailing -->
          </button>
        </h5>
      </div>
    </div>

    <div class="card" [ngClass]="industries ? '' : 'd-none'">
      <div
        class="card-header"
        id="headingTwo"
        [ngClass]="industries ? 'active-header' : ''"
      >
        <h5 class="mb-0 paragraph-semibold-18">
          <button
            id="activeheaderindustries"
            class="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
            (click)="clickedAboutUsLink(true, 'ourStory');navToggel()"
          >
            Our Story
          </button>
        </h5>
      </div>
    </div>

    <div class="card" [ngClass]="sustainibility ? '' : 'd-none'">
      <div
        class="card-header"
        id="headingTwo"
        [ngClass]="sustainibility ? 'active-header' : ''"
      >
        <h5 class="mb-0 paragraph-semibold-18">
          <button
            id="activeheadersustainibility"
            class="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
            (click)="clickedAboutUsLink(true, 'sustainibility')"
          >
            <span>
              <a
                (click)="navToggel(); gotoFooter()"
                [style.color]="'white'"
                href="javascript:void(0)"
              >
                Contact
              </a>
            </span>
          </button>
        </h5>
      </div>
    </div>
  </div>
</div>
