import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  changeBulkLinkedIn:boolean = false;
  changeShipLinkedIn:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  mouseEvent(check:boolean, name:string){
    if(name == 'bulk'){
      if(check){
        this.changeBulkLinkedIn = true;
      }else{
        this.changeBulkLinkedIn = false;
      }
    }else{
      if(check){
        this.changeShipLinkedIn = true;
      }else{
        this.changeShipLinkedIn = false;
      }

    }
    
  }

}
