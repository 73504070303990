<footer class="bg-grey-20" id="targetFooter">
  <div class="footer-container">
    <div class="col-50">
      <img src="../../assets/media/cnco-black-logo.png" />
      <p class="paragraph-regular-16 Text-100 left-text-footer">
        For enquiries regarding our Group companies’ history and heritage,
        please contact us at swirearchives@jsshk.com
      </p>
    </div>
    <div class="col-50">
      <div class="two-column">
        <div class="footer-column">
          <img src="../../../../assets/media/swire-black-shipping.png" />
          <p class="paragraph-regular-16 Text-100 Display-family">
            300 Beach Road, #27-01,<br />
            The Concourse<br />
            Singapore 199555<br />
            <br />
            <a href="https://www.swireshipping.com/enquiry"
                class="sign-btn swi-button-darkgreyblue-small-default-regular">
                <!-- <span class="icon-customer-service1 swi-icon Primary-80"></span> -->
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79731 3.37523C4.86459 2.17454 6.31213 1.5 7.82149 1.5C9.33086 1.5 10.7784 2.17454 11.8457 3.37523C12.913 4.57592 13.5125 6.20441 13.5125 7.90244C13.5125 8.20552 13.2942 8.45122 13.0247 8.45122C12.7553 8.45122 12.5369 8.20552 12.5369 7.90244C12.5369 6.4955 12.0401 5.14618 11.1558 4.15132C10.2715 3.15647 9.07211 2.59756 7.82149 2.59756C6.57088 2.59756 5.37149 3.15647 4.48717 4.15132C3.60285 5.14618 3.10605 6.4955 3.10605 7.90244C3.10605 8.20552 2.88765 8.45122 2.61824 8.45122C2.34884 8.45122 2.13044 8.20552 2.13044 7.90244C2.13044 6.20441 2.73003 4.57592 3.79731 3.37523Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9315 7.49835C12.0619 7.46715 12.197 7.46977 12.3263 7.50602L12.327 7.50621L13.4585 7.82516C13.8453 7.93401 14.189 8.18525 14.4344 8.53882C14.6799 8.89247 14.8133 9.32828 14.8133 9.77699V11.1505C14.8133 11.5992 14.6799 12.0351 14.4344 12.3887C14.1889 12.7424 13.8454 12.9936 13.4585 13.1024L12.3268 13.4207C12.1973 13.457 12.0617 13.4597 11.9311 13.4284C11.8006 13.3971 11.6781 13.3327 11.573 13.2402C11.4679 13.1477 11.3829 13.0294 11.3246 12.8944C11.2662 12.7595 11.236 12.6114 11.2361 12.4614V8.46439C11.2361 8.31438 11.2666 8.16615 11.325 8.03134C11.3835 7.89653 11.4685 7.77847 11.5736 7.68612C11.6787 7.59377 11.8011 7.52956 11.9315 7.49835ZM12.2117 8.6052L13.2216 8.88988C13.3975 8.93934 13.5539 9.0536 13.6655 9.21435C13.7771 9.37508 13.8377 9.57314 13.8377 9.77707V11.1505C13.8377 11.3544 13.7771 11.5525 13.6655 11.7132C13.5539 11.874 13.3978 11.9881 13.2219 12.0376L12.2117 12.3217V8.6052Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.54883 13.4284C3.4184 13.4596 3.28337 13.457 3.15398 13.4207L2.02187 13.1016C1.63503 12.9927 1.29135 12.7415 1.04591 12.3879C0.800425 12.0343 0.667053 11.5985 0.666992 11.1498V9.77625C0.667053 9.32754 0.800425 8.89165 1.04591 8.538C1.2914 8.18435 1.63491 7.93316 2.02185 7.82434L3.15356 7.50605C3.28307 7.46977 3.41865 7.46705 3.54919 7.49835C3.67972 7.52964 3.80219 7.594 3.9073 7.68653C4.01241 7.77907 4.09738 7.89734 4.15577 8.03236C4.2141 8.16727 4.24435 8.31534 4.24423 8.46536V12.4623C4.24418 12.6124 4.21376 12.7606 4.15528 12.8954C4.09681 13.0302 4.01181 13.1483 3.90673 13.2406C3.80165 13.333 3.67925 13.3972 3.54883 13.4284ZM3.26862 12.3215L2.25873 12.0369C2.08284 11.9874 1.92643 11.8731 1.81484 11.7124C1.70327 11.5517 1.64265 11.3536 1.6426 11.1497V9.77625C1.64265 9.57232 1.70327 9.37426 1.81484 9.21353C1.92642 9.05278 2.08257 8.9386 2.25845 8.88914L3.26862 8.60503V12.3215Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5369 13.3902V13.0244H13.5125V13.3902C13.5125 13.9239 13.3241 14.4357 12.9887 14.8131C12.6532 15.1904 12.1983 15.4024 11.7239 15.4024H9.44751V14.3049H11.7239C11.9396 14.3049 12.1463 14.2085 12.2988 14.037C12.4513 13.8655 12.5369 13.6328 12.5369 13.3902Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84585 14.3049C6.71648 14.3049 6.5924 14.3627 6.50092 14.4656C6.40944 14.5685 6.35805 14.7081 6.35805 14.8537C6.35805 14.9992 6.40944 15.1388 6.50092 15.2417C6.5924 15.3446 6.71648 15.4024 6.84585 15.4024H8.79707C8.92645 15.4024 9.05052 15.3446 9.142 15.2417C9.23348 15.1388 9.28488 14.9992 9.28488 14.8537C9.28488 14.7081 9.23348 14.5685 9.142 14.4656C9.05052 14.3627 8.92645 14.3049 8.79707 14.3049H6.84585ZM5.81106 13.6895C6.08551 13.3808 6.45773 13.2073 6.84585 13.2073H8.79707C9.18519 13.2073 9.55742 13.3808 9.83186 13.6895C10.1063 13.9983 10.2605 14.417 10.2605 14.8537C10.2605 15.2903 10.1063 15.709 9.83186 16.0178C9.55742 16.3265 9.18519 16.5 8.79707 16.5H6.84585C6.45773 16.5 6.08551 16.3265 5.81106 16.0178C5.53662 15.709 5.38244 15.2903 5.38244 14.8537C5.38244 14.417 5.53662 13.9983 5.81106 13.6895Z" fill="#1E4F98"/>
                </svg>
                &nbsp; Contact Us
            </a>
          </p>
          <div class="in-btn">
            <a
              (mouseenter)="mouseEvent(true, 'ship')"
              (mouseleave)="mouseEvent(false, 'ship')"
              target="_blank"
              href="https://www.linkedin.com/company/swire-shipping/"
              class="social-icon"
            >
              <ng-container *ngIf="!changeShipLinkedIn">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32 3H8C5.23858 3 3 5.23858 3 8V32C3 34.7614 5.23858 37 8 37H32C34.7614 37 37 34.7614 37 32V8C37 5.23858 34.7614 3 32 3ZM8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0H8Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.3692 15.9692H8.394V32H13.3692V15.9692Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.7674 10.8887C13.7674 12.4843 12.4722 13.7775 10.8837 13.7775C9.28677 13.7775 7.99582 12.4843 8.00001 10.8887C8.00001 10.5094 8.0746 10.1337 8.21952 9.78326C8.36444 9.43279 8.57685 9.11433 8.84462 8.84609C9.1124 8.57785 9.43029 8.36506 9.78016 8.21989C10.13 8.07472 10.505 8 10.8837 8C11.2624 8 11.6374 8.07472 11.9872 8.21989C12.3371 8.36506 12.655 8.57785 12.9228 8.84609C13.1905 9.11433 13.403 9.43279 13.5479 9.78326C13.6928 10.1337 13.7674 10.5094 13.7674 10.8887Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M24.4429 19.9538C21.8568 19.9538 21.4586 21.9776 21.4586 24.0686V32H16.4876V15.9692H21.2616V18.161H21.3287C21.9951 16.9013 23.6172 15.5703 26.0356 15.5703C31.0695 15.5703 32 18.8915 32 23.2078V32H27.029V24.2029C27.029 22.3429 26.9913 19.9538 24.4429 19.9538Z"
                    fill="#0B203E"
                  />
                </svg>
              </ng-container>
              <ng-container *ngIf="changeShipLinkedIn">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#0B203E" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32 3H8C5.23858 3 3 5.23858 3 8V32C3 34.7614 5.23858 37 8 37H32C34.7614 37 37 34.7614 37 32V8C37 5.23858 34.7614 3 32 3ZM8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0H8Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.3692 15.9692H8.394V32H13.3692V15.9692Z"
                    fill="white"
                  />
                  <path
                    d="M13.7674 10.8887C13.7674 12.4843 12.4722 13.7775 10.8837 13.7775C9.28677 13.7775 7.99582 12.4843 8.00001 10.8887C8.00001 10.5094 8.0746 10.1337 8.21952 9.78326C8.36444 9.43279 8.57685 9.11433 8.84462 8.84609C9.1124 8.57785 9.43029 8.36506 9.78016 8.21989C10.13 8.07472 10.505 8 10.8837 8C11.2624 8 11.6374 8.07472 11.9872 8.21989C12.3371 8.36506 12.655 8.57785 12.9228 8.84609C13.1905 9.11433 13.403 9.43279 13.5479 9.78326C13.6928 10.1337 13.7674 10.5094 13.7674 10.8887Z"
                    fill="white"
                  />
                  <path
                    d="M24.4429 19.9538C21.8568 19.9538 21.4586 21.9776 21.4586 24.0686V32H16.4876V15.9692H21.2616V18.161H21.3287C21.9951 16.9013 23.6172 15.5703 26.0356 15.5703C31.0695 15.5703 32 18.8915 32 23.2078V32H27.029V24.2029C27.029 22.3429 26.9913 19.9538 24.4429 19.9538Z"
                    fill="white"
                  />
                </svg>
              </ng-container>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/swire-shipping/jobs/"
              class="join-btn"
            >
              Join Us
            </a>
          </div>
        </div>
        <div class="footer-column">
          <img src="../../../../assets/media/swire-black-bulk.svg" />
          <p class="paragraph-regular-16 Text-100 Display-family">
            300 Beach Road, #28-02,<br />
            The Concourse<br />
            Singapore 199555<br />
            <br />
            <a href="https://swirebulk.com/contact-us/"
                class="sign-btn swi-button-darkgreyblue-small-default-regular">
                <!-- <span class="icon-customer-service1 swi-icon Primary-80"></span> -->
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79731 3.37523C4.86459 2.17454 6.31213 1.5 7.82149 1.5C9.33086 1.5 10.7784 2.17454 11.8457 3.37523C12.913 4.57592 13.5125 6.20441 13.5125 7.90244C13.5125 8.20552 13.2942 8.45122 13.0247 8.45122C12.7553 8.45122 12.5369 8.20552 12.5369 7.90244C12.5369 6.4955 12.0401 5.14618 11.1558 4.15132C10.2715 3.15647 9.07211 2.59756 7.82149 2.59756C6.57088 2.59756 5.37149 3.15647 4.48717 4.15132C3.60285 5.14618 3.10605 6.4955 3.10605 7.90244C3.10605 8.20552 2.88765 8.45122 2.61824 8.45122C2.34884 8.45122 2.13044 8.20552 2.13044 7.90244C2.13044 6.20441 2.73003 4.57592 3.79731 3.37523Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9315 7.49835C12.0619 7.46715 12.197 7.46977 12.3263 7.50602L12.327 7.50621L13.4585 7.82516C13.8453 7.93401 14.189 8.18525 14.4344 8.53882C14.6799 8.89247 14.8133 9.32828 14.8133 9.77699V11.1505C14.8133 11.5992 14.6799 12.0351 14.4344 12.3887C14.1889 12.7424 13.8454 12.9936 13.4585 13.1024L12.3268 13.4207C12.1973 13.457 12.0617 13.4597 11.9311 13.4284C11.8006 13.3971 11.6781 13.3327 11.573 13.2402C11.4679 13.1477 11.3829 13.0294 11.3246 12.8944C11.2662 12.7595 11.236 12.6114 11.2361 12.4614V8.46439C11.2361 8.31438 11.2666 8.16615 11.325 8.03134C11.3835 7.89653 11.4685 7.77847 11.5736 7.68612C11.6787 7.59377 11.8011 7.52956 11.9315 7.49835ZM12.2117 8.6052L13.2216 8.88988C13.3975 8.93934 13.5539 9.0536 13.6655 9.21435C13.7771 9.37508 13.8377 9.57314 13.8377 9.77707V11.1505C13.8377 11.3544 13.7771 11.5525 13.6655 11.7132C13.5539 11.874 13.3978 11.9881 13.2219 12.0376L12.2117 12.3217V8.6052Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.54883 13.4284C3.4184 13.4596 3.28337 13.457 3.15398 13.4207L2.02187 13.1016C1.63503 12.9927 1.29135 12.7415 1.04591 12.3879C0.800425 12.0343 0.667053 11.5985 0.666992 11.1498V9.77625C0.667053 9.32754 0.800425 8.89165 1.04591 8.538C1.2914 8.18435 1.63491 7.93316 2.02185 7.82434L3.15356 7.50605C3.28307 7.46977 3.41865 7.46705 3.54919 7.49835C3.67972 7.52964 3.80219 7.594 3.9073 7.68653C4.01241 7.77907 4.09738 7.89734 4.15577 8.03236C4.2141 8.16727 4.24435 8.31534 4.24423 8.46536V12.4623C4.24418 12.6124 4.21376 12.7606 4.15528 12.8954C4.09681 13.0302 4.01181 13.1483 3.90673 13.2406C3.80165 13.333 3.67925 13.3972 3.54883 13.4284ZM3.26862 12.3215L2.25873 12.0369C2.08284 11.9874 1.92643 11.8731 1.81484 11.7124C1.70327 11.5517 1.64265 11.3536 1.6426 11.1497V9.77625C1.64265 9.57232 1.70327 9.37426 1.81484 9.21353C1.92642 9.05278 2.08257 8.9386 2.25845 8.88914L3.26862 8.60503V12.3215Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5369 13.3902V13.0244H13.5125V13.3902C13.5125 13.9239 13.3241 14.4357 12.9887 14.8131C12.6532 15.1904 12.1983 15.4024 11.7239 15.4024H9.44751V14.3049H11.7239C11.9396 14.3049 12.1463 14.2085 12.2988 14.037C12.4513 13.8655 12.5369 13.6328 12.5369 13.3902Z" fill="#1E4F98"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84585 14.3049C6.71648 14.3049 6.5924 14.3627 6.50092 14.4656C6.40944 14.5685 6.35805 14.7081 6.35805 14.8537C6.35805 14.9992 6.40944 15.1388 6.50092 15.2417C6.5924 15.3446 6.71648 15.4024 6.84585 15.4024H8.79707C8.92645 15.4024 9.05052 15.3446 9.142 15.2417C9.23348 15.1388 9.28488 14.9992 9.28488 14.8537C9.28488 14.7081 9.23348 14.5685 9.142 14.4656C9.05052 14.3627 8.92645 14.3049 8.79707 14.3049H6.84585ZM5.81106 13.6895C6.08551 13.3808 6.45773 13.2073 6.84585 13.2073H8.79707C9.18519 13.2073 9.55742 13.3808 9.83186 13.6895C10.1063 13.9983 10.2605 14.417 10.2605 14.8537C10.2605 15.2903 10.1063 15.709 9.83186 16.0178C9.55742 16.3265 9.18519 16.5 8.79707 16.5H6.84585C6.45773 16.5 6.08551 16.3265 5.81106 16.0178C5.53662 15.709 5.38244 15.2903 5.38244 14.8537C5.38244 14.417 5.53662 13.9983 5.81106 13.6895Z" fill="#1E4F98"/>
                </svg>
                &nbsp; Contact Us
            </a>
          </p>
          <div class="in-btn">
            <a
              (mouseenter)="mouseEvent(true, 'bulk')"
              (mouseleave)="mouseEvent(false, 'bulk')"
              target="_blank"
              href="https://www.linkedin.com/company/swirebulk/"
              class="social-icon"
            >
              <ng-container *ngIf="!changeBulkLinkedIn">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32 3H8C5.23858 3 3 5.23858 3 8V32C3 34.7614 5.23858 37 8 37H32C34.7614 37 37 34.7614 37 32V8C37 5.23858 34.7614 3 32 3ZM8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0H8Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.3692 15.9692H8.394V32H13.3692V15.9692Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.7674 10.8887C13.7674 12.4843 12.4722 13.7775 10.8837 13.7775C9.28677 13.7775 7.99582 12.4843 8.00001 10.8887C8.00001 10.5094 8.0746 10.1337 8.21952 9.78326C8.36444 9.43279 8.57685 9.11433 8.84462 8.84609C9.1124 8.57785 9.43029 8.36506 9.78016 8.21989C10.13 8.07472 10.505 8 10.8837 8C11.2624 8 11.6374 8.07472 11.9872 8.21989C12.3371 8.36506 12.655 8.57785 12.9228 8.84609C13.1905 9.11433 13.403 9.43279 13.5479 9.78326C13.6928 10.1337 13.7674 10.5094 13.7674 10.8887Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M24.4429 19.9538C21.8568 19.9538 21.4586 21.9776 21.4586 24.0686V32H16.4876V15.9692H21.2616V18.161H21.3287C21.9951 16.9013 23.6172 15.5703 26.0356 15.5703C31.0695 15.5703 32 18.8915 32 23.2078V32H27.029V24.2029C27.029 22.3429 26.9913 19.9538 24.4429 19.9538Z"
                    fill="#0B203E"
                  />
                </svg>
              </ng-container>
              <ng-container *ngIf="changeBulkLinkedIn">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#0B203E" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32 3H8C5.23858 3 3 5.23858 3 8V32C3 34.7614 5.23858 37 8 37H32C34.7614 37 37 34.7614 37 32V8C37 5.23858 34.7614 3 32 3ZM8 0C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0H8Z"
                    fill="#0B203E"
                  />
                  <path
                    d="M13.3692 15.9692H8.394V32H13.3692V15.9692Z"
                    fill="white"
                  />
                  <path
                    d="M13.7674 10.8887C13.7674 12.4843 12.4722 13.7775 10.8837 13.7775C9.28677 13.7775 7.99582 12.4843 8.00001 10.8887C8.00001 10.5094 8.0746 10.1337 8.21952 9.78326C8.36444 9.43279 8.57685 9.11433 8.84462 8.84609C9.1124 8.57785 9.43029 8.36506 9.78016 8.21989C10.13 8.07472 10.505 8 10.8837 8C11.2624 8 11.6374 8.07472 11.9872 8.21989C12.3371 8.36506 12.655 8.57785 12.9228 8.84609C13.1905 9.11433 13.403 9.43279 13.5479 9.78326C13.6928 10.1337 13.7674 10.5094 13.7674 10.8887Z"
                    fill="white"
                  />
                  <path
                    d="M24.4429 19.9538C21.8568 19.9538 21.4586 21.9776 21.4586 24.0686V32H16.4876V15.9692H21.2616V18.161H21.3287C21.9951 16.9013 23.6172 15.5703 26.0356 15.5703C31.0695 15.5703 32 18.8915 32 23.2078V32H27.029V24.2029C27.029 22.3429 26.9913 19.9538 24.4429 19.9538Z"
                    fill="white"
                  />
                </svg>
              </ng-container>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/swirebulk/jobs/"
              class="join-btn"
            >
              Join Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bootom-bar">
    <div class="col-50">
      <p class="paragraph-bold-14 Display-family Text-10" routerLink="/">2022 CNCO</p>
    </div>
    <div class="col-50 right-col">
      <ul>
        <li>
          <a
            target="_blank"
            class="paragraph-bold-14 Display-family Text-10"
            href="https://www.swireshipping.com/information/policies/terms-of-use/"
            >Terms of Use
          </a>
        </li>
        <li>
          <a
            target="_blank"
            class="paragraph-bold-14 Display-family Text-10"
            href="https://www.swireshipping.com/information/policies/cookies-policy/"
            >Cookies Policy</a
          >
        </li>
        <li>
          <a
            target="_blank"
            class="paragraph-bold-14 Display-family Text-10"
            href="https://www.swireshipping.com/information/info-pages/sustainability/modern-slavery/"
            >Modern Slavery
          </a>
        </li>
        <li>
          <a
            target="_blank"
            class="paragraph-bold-14 Display-family Text-10"
            href="https://www.swireshipping.com/information/policies/data-protection-and-privacy-policy/"
            >Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
