import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class EnvConstant {
    validateServerEnv() {
        let szHostName = window.location.hostname;
        if (szHostName == 'localhost') {
            return true;
        }
        if (szHostName == 'swire-cnco-dev.swiredigital-s3-staging.com') {
            return true;
        } else if (szHostName == 'swirecnco.com' || szHostName == 'www.swirecnco.com' || szHostName.includes('swirecnco.com')) {
            return true;
        } else {
            return false;
        }
    }
}


