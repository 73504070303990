import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvConstant } from './data/service/env';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private _router: Router,
    private _envConstant: EnvConstant) {
    if (!this._envConstant.validateServerEnv()) {
      this._router.navigate(['/404']);
    }
  }
  title = 'cnco';
  ngOnInit(): void {
    this._router.events.subscribe(event => {
      window.scrollTo(0, 0);
    });
  }
}
